import herosecbg from './herosecbg.jpg'
// import ias from './IAS.png'
import ias from './IAS.jpg'
import iaf from './IAF.svg'
import cqiirca from './CQIIRCA.jpg'
import eg from './exemplarglobal.png'
// import eosh from './eosh.png'
import eosh from './eosh.jpg'
import slider1 from './slider1.jpg'
import slider2 from './slider2.jpg'
import slider3 from './slider3.jpg'
import slider4 from './slider4.jpg'
import slider5 from './slider5.jpg'
import slider6 from './slider6.jpg'
import slider7 from './slider7.jpg'
import carrerpic from './carrerpic.jpg'

import c1 from './c1.jpg'
import c2 from './c2.jpg'
import c3 from './c3.jpg'
import c4 from './c4.jpg'
import c5 from './c5.jpg'
import c6 from './c6.jpg'
import c7 from './c7.jpg'

import appeals from './appeals.png'
import CertProcess from './CertProcess.png'
import gms1 from './gms1.png'
import gms2 from './gms2.png'
import gms3 from './gms3.png'
import gms4 from './gms4.png'
import gms5 from './gms5.png'
import gms6 from './gms6.png'
import gms7 from './gms7.png'
import gms8 from './gms8.png'
import gms9 from './gms9.png'
import gms10 from './gms10.png'
import gms11 from './gms11.png'


import tvecertLogo from './tvecertLogo.png'

import leadAuditor from './leadAuditor.jpg'
import Conversion from './Conversion.jpg'
import Internal from './Internal.jpg'
import Transition from './Transition.jpg'


import bahraingalvanizing from './bahraingalvanizing.png'
import canarabank from './canarabank.jfif'
import Casagrand from './Casagrand.png'
import emco from './emco.jpg'
import emirates from './emirates.png'
import gastrocare from './gastrocare.jfif'
import holycross from './holycross.png'
import sriAndal from './sriAndal.png'

import madrasNetworking from './madrasNetworking.avif'
import muscatsteel from './muscatsteel.png'
import noormuscat from './noormuscat.png'
import NEZOoilfield from './NEZOoilfield.png'
import ocentra from './ocentra.png'
import orbitalfarication from './orbitalfarication.png'
import SRIBALAJIMEDICALCOLLEGE from './SRIBALAJIMEDICALCOLLEGE.png'
import vkapolymers from './vkapolymers.png'

import skyway from './skyway.png'
import westpoint from './westpoint.png'
import parkeroil from './parkeroil.png'
import KMSPECIALITY from './KMSPECIALITY.avif'
import poonacollege from './poonacollege.jpg'
import pacifichightech from './pacifichightech.png'
import jenomaranbuilders from './jenomaranbuilders.png'
import permasteelisa from './permasteelisa.png'
import sivachidambaramjewellery from './sivachidambaramjewellery.jfif'
import vailakanni from './vailakanni.png'






export default {
herosecbg,
ias,
iaf,
cqiirca,
eg,
eosh,
slider1,
slider2,
slider3,
slider4,
slider5,
slider6,
slider7,
carrerpic,
c1,
c2,
c3,
c4,
c5,
c6,
c7,
appeals,
CertProcess,

gms1,
gms2,
gms3,
gms4,
gms5,
gms6,
gms7,
gms8,
gms9,
gms10,
gms11,

tvecertLogo,

leadAuditor,
Conversion,
Internal,
Transition,

bahraingalvanizing,
canarabank,
Casagrand,
emco,
emirates,
gastrocare,
holycross,
sriAndal,

madrasNetworking,
muscatsteel,
noormuscat,
NEZOoilfield,
ocentra,
orbitalfarication,
SRIBALAJIMEDICALCOLLEGE,
vkapolymers,

pacifichightech,
skyway,
westpoint,
parkeroil,
KMSPECIALITY,
poonacollege,

jenomaranbuilders,
permasteelisa,
sivachidambaramjewellery,
vailakanni


}

