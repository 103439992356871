import React from 'react'

const Policy3 = () => {
  return (
    <div className=" flex flex-col gap-3">
          <b className=' text-H text-darkblue'>Terms and conditions of use</b>
          <b>Introduction</b>
          <div>
            <b>
              <b>1.</b>These terms and conditions shall govern your
              use of our website.
            </b>
            <p>
              <b>1.1.</b>By using our website, you accept these terms and
              conditions in full; accordingly, if you disagree with these terms
              and conditions or any part of these terms and conditions, you must
              not use our website.
            </p>
            <p>
              <b>1.2.</b>If you [register with our website, submit any material
              to our website or use any of our website services], we will ask
              you to expressly agree to these terms and conditions.
            </p>
            <p>
              <b>1.3.</b>You must be at least [18] years of age to use our
              website; by using our website or agreeing to these terms and
              conditions, you warrant and represent to us that you are at least
              [18] years of age.
            </p>
            <p>
              <b>1.4.</b>Our website uses cookies; by using our website or
              agreeing to these terms and conditions, you consent to our use of
              cookies in accordance with the terms of our [privacy and
              cookies policy].
            </p>
          </div>

          <div>
            <b>
              <b>2.</b>Licence to use website
            </b>
            <p>
              <b>2.1.</b>You may:
            </p>
            <p>
              <b>(a)</b>view pages from our website in a web browser;
            </p>
            <p>
              <b>(b)</b>download pages from our website for caching in a web
              browser;
            </p>
            <p>
              <b>(c)</b>print pages from our website;
            </p>
            <p>
              subject to the other provisions of these terms and conditions.
            </p>
            <p>
              <b>2.2.</b>Except as expressly permitted BY THE provisions of
              these terms and conditions, you must not download any material
              from our website or save any such material to your computer.
            </p>
            <p>
              <b>2.3.</b>You may only use our website for [your own personal and
              business purposes, and you must not use our website for
              any other purposes.
            </p>
            <p>
              <b>2.4.</b>Except as expressly permitted by these terms and
              conditions, you must not edit or otherwise modify any
              material on our website.
            </p>

            <p>
              <b>2.5.</b>Unless you own or control the relevant rights in the
              material, you must not:
            </p>
            <p>
              <b>(a)</b> republish material from our website (including
              republication on another website);
            </p>
            <p>
              <b>(b)</b>sell, rent or sub-license material from our website;
            </p>
            <p>
              <b>(c)</b>show any material from our website in public;
            </p>
            <p>
              <b>(d)</b>show any material from our website in public;
            </p>
            <p>
              <b>(e)</b>exploit material from our website for a commercial
              purpose; or
            </p>
            <p>
              <b>2.6.</b>We reserve the right to restrict access to areas of our
              website, or indeed our whole website, at our discretion; you must
              not circumvent or bypass, or attempt to circumvent or bypass, any
              access restriction measures on our website.
            </p>
          </div>

          <div>
            <b>
              <b>3.</b>Acceptable use
            </b>
            <p>
              <b>3.1.</b>Acceptable use{" "}
            </p>
            <p>
              <b>(a)</b>use our website in any way or take any action that
              causes, or may cause, damage to the website or impairment of the
              performance, availability or accessibility of the website;
            </p>
            <p>
              <b>(b)</b>use our website in any way that is unlawful, illegal,
              fraudulent or harmful, or in connection with any unlawful,
              illegal, fraudulent or harmful purpose or activity;
            </p>
            <p>
              <b>(c)</b>use our website to copy, store, host, transmit, send,
              use, publish or distribute any material which consists of (or is
              linked to) any spyware, computer virus, Trojan horse, worm,
              keystroke logger, rootkit or other malicious computer software;
            </p>
            <p>
              <b>(d)</b> [conduct any systematic or automated data collection
              activities (including without limitation scraping, data mining,
              data extraction and data harvesting) on or in relation to our
              website without our express written consent];
            </p>
            <p>
              <b>(e)</b>[access or otherwise interact with our website using any
              robot, spider or other automated means, except for the purpose of
              [search engine indexing]];
            </p>
            <p>
              <b>(f)</b>[violate the directives set out in the robots.txt file
              for our website]; or
            </p>
            <p>
              <b>(g)</b>[use data collected from our website for any direct
              marketing activity (including without limitation email marketing,
              SMS marketing, telemarketing and direct mailing)].
            </p>
            <p>
              <b>3.2.</b> You must not use data collected from our website to
              contact individuals, companies or other persons or entities.
            </p>
            <p>
              <b>3.3.</b>You must ensure that all the information you supply to
              us through our website, or in relation to our website, is [true,
              accurate, current, complete and non-misleading].
            </p>
          </div>

          <div>
            <b>
              <b>4.</b>Registration and accounts
            </b>
            <p>
              <b>4.1.</b>You may register for an account with our website by
              [completing and submitting the account registration form on our
              website, and clicking on the verification link in the email that
              the website will send to you].
            </p>
            <p>
              <b>4.2.</b>You must not allow any other person to use your account
              to access the website.
            </p>
            <p>
              <b>4.3.</b>You must notify us in writing immediately if you become
              aware of any unauthorised use of your account.
            </p>
            <p>
              <b>4.4.</b>You must not use any other person's account to access
              the website[, unless you have that person's express
              permission to do so].
            </p>
          </div>

          <div>
            <b>
              <b>5.</b>User login details
            </b>
            <p>
              <b>5.1.</b>If you register for an account with our website, [we
              will provide you with] OR [you will be asked to choose] [a user ID
              and password].
            </p>
            <p>
              <b>5.2.</b>Your user ID must not be liable to mislead; you must
              not use your account or user ID for or in connection with the
              impersonation of any person.
            </p>
            <p>
              <b>5.3.</b>You must keep your password confidential.
            </p>
            <p>
              <b>5.4.</b>You must notify us in writing immediately if you become
              aware of any disclosure of your password.
            </p>
            <p>
              <b>5.5.</b>You are responsible for any activity on our website
              arising out of any failure to keep your password confidential, and
              may be held liable for any losses arising out of such a failure.
            </p>
          </div>

          <div>
            <b>
              <b>6.</b>Cancellation and suspension of account
            </b>
            <p>
              <b>6.1.</b>We may:
            </p>
            <p>
              <b>(a)</b>[suspend your account];
            </p>
            <p>
              <b>(b)</b>[cancel your account]; and/or
            </p>
            <p>
              <b>(c)</b>[edit your account details], at any time in our sole
              discretion without notice or explanation.
            </p>
            <p>
              <b>6.2.</b>You may cancel your account on our website [using your
              account control panel on the website].
            </p>
          </div>

          <div>
            <b>
              7.<b></b>Your content: licence
            </b>
            <p>
              <b>7.1.</b>In these terms and conditions, "your content" means all
              works and materials (including without limitation text, graphics,
              images, audio material, video material, audio-visual material,
              scripts, software and files) that you submit to us or our website
              for storage or publication on, processing by, or transmission via,
              our website.
            </p>
            <p>
              <b>7.2.</b>You grant to us a [worldwide, irrevocable,
              non-exclusive, royalty-free licence] to [use, reproduce, store,
              adapt, publish, translate and distribute your content in any
              existing or future media] OR [reproduce, store and publish your
              content on and in relation to this website and any successor
              website] OR [reproduce, store and, with your specific consent,
              publish your content on and in relation to this website].
            </p>
            <p>
              <b>7.3.</b>You hereby waive all your moral rights in your content
              to the maximum extent permitted by applicable law; and you warrant
              and represent that all other moral rights in your content have
              been waived to the maximum extent permitted by applicable law.
            </p>
            <p>
              <b>7.4.</b> You may edit your content to the extent permitted
              using the editing functionality made available on our website.
            </p>
            <p>
              <b>7.5.</b>Without prejudice to our other rights under these terms
              and conditions, if you breach any provision of these terms and
              conditions in any way, or if we reasonably suspect that you have
              breached these terms and conditions in any way, we may delete,
              unpublish or edit any or all of your content.
            </p>
          </div>

          <div>
            <b>
              <b>8.</b>Your content: rules
            </b>
            <p>
              <b>8.1.</b>You warrant and represent that your content will comply
              with these terms and conditions.
            </p>
            <p>
              <b>8.2.</b>Your content must not be illegal or unlawful, must not
              infringe any person's legal rights, and must not be capable of
              giving rise to legal action against any person (in each case in
              any jurisdiction and under any applicable law).
            </p>
            <p>
              <b>8.3.</b>Your content, and the use of your content by us in
              accordance with these terms and conditions, must not:
            </p>
            <p>
              <b>(a)</b>be libellous or maliciously false;
            </p>
            <p>
              <b>(b)</b>be obscene or indecent;
            </p>
            <p>
              <b>(c)</b>infringe any copyright, moral right, database right,
              trade mark right, design right, right in passing off, or other
              intellectual property right;
            </p>
            <p>
              <b>(d)</b>infringe any right of confidence, right of privacy or
              right under data protection legislation;
            </p>
            <p>
              <b>(e)</b>constitute negligent advice or contain any negligent
              statement;
            </p>
            <p>
              <b>(f)</b>constitute an incitement to commit a crimel,
              instructions for the commission of a crime or the promotion of
              criminal activity];
            </p>
            <p>
              <b>(g)</b>be in contempt of any court, or in breach of any court
              order;
            </p>
            <p>
              <b>(h)</b>be in breach of racial or religious hatred or
              discrimination legislation;
            </p>
            <p>
              <b>(i)</b>be blasphemous;
            </p>
            <p>
              <b>(j)</b>be in breach of official secrets legislation;
            </p>
            <p>
              <b>(k)</b> be in breach of any contractual obligation owed to any
              person;
            </p>
            <p>
              <b>(l)</b>[depict violencel in an explicit, graphic or gratuitous
              manner]l;
            </p>
            <p>
              <b>(m)</b>[be pornographic, lewd, suggestive or sexually
              explicit]];
            </p>
            <p>
              <b>(n)</b>[be untrue, false, inaccurate or misleading];
            </p>
            <p>
              <b>(o)</b>[consist of or contain any instructions, advice or other
              information which may be acted upon and could, if acted upon,
              cause illness, injury or death, or any other loss or damage];
            </p>
            <p>
              <b>(p)</b>[constitute spam];
            </p>
            <p>
              <b>(q)</b>[be offensive, deceptive, fraudulent, threatening,
              abusive, harassing, anti-social, menacing, hateful, discriminatory
              or inflammatory]; or
            </p>
            <p>
              <b>(r)</b> [cause annoyance, inconvenience or needless
              anxiety to any person].
            </p>
          </div>

          <div>
            <b>
              <b>9.</b>Limited warranties
            </b>
            <p>
              <b>9.1.</b>We do not warrant or represent:
            </p>
            <p>
              <b>(a)</b>the completeness or accuracy of the information
              published on our website;
            </p>
            <p>
              <b>(b)</b>that the material on the website is up to date; or
            </p>
            <p>
              <b>(c)</b>that the website or any service on the website will
              remain available.
            </p>
            <p>
              <b>9.2.</b> We reserve the right to discontinue or alter any or
              all of our website services, and to stop publishing our website,
              at any time in our sole discretion without notice or explanation;
              and save to the extent expressly provided otherwise in these terms
              and conditions, you will not be entitled to any compensation or
              other payment upon the discontinuance or alteration of any website
              services, or if we stop publishing the website.
            </p>
            <p>
              <b>9.3.</b>To the maximum extent permitted by applicable law, we
              exclude all representations and warranties relating to the subject
              matter of these terms and conditions, our website and the
              use of our website.
            </p>
          </div>

          <div>
            <b>
              <b>10.</b>Breaches of these terms and conditions
            </b>
            <p>
              10.1.<b></b>Without prejudice to our other rights under these
              terms and conditions, if you breach these terms and conditions in
              any way, or if we reasonably suspect that you have breached these
              terms and conditions in any way, we may:
            </p>
            <p>
              <b>(a)</b>send you one or more formal warnings;
            </p>
            <p>
              <b>(b)</b>temporarily suspend your access to our website;
            </p>
            <p>
              <b>(c)</b>permanently prohibit you from accessing our website;
            </p>
            <p>
              <b>(d)</b>[block computers using your IP address from accessing
              our website];
            </p>
            <p>
              <b>(e)</b>[contact any or all of your internet service providers
              and request that they block your access to our website];
            </p>
            <p>
              <b>(f)</b>commence legal action against you, whether for breach of
              contract or otherwise; and/or
            </p>
            <p>
              <b>(g)</b>[suspend or delete your account on our website].{" "}
            </p>
            <p>
              <b>10.2.</b>Where we suspend or prohibit or block your access to
              our website or a part of our website, you must not take any action
              to circumvent such suspension or prohibition or blocking including
              without limitation [creating and/or using a different account].
            </p>
          </div>

          <div>
            <b>
              <b>11.</b>Variation
            </b>
            <p>
              <b>11.1.</b>We may revise these terms and conditions from time to
              time.
            </p>
            <p>
              <b>11.2.</b>[The revised terms and conditions shall apply to the
              use of our website from the date of publication of the revised
              terms and conditions on the website, and you hereby waive any
              right you may otherwise have to be notified of, or to consent to,
              revisions of these terms and conditions.] OR [We will give you
              written notice of any revision of these terms and conditions, and
              the revised terms and conditions will apply to the use of our
              website from the date that we give you such notice; if you do not
              agree to the revised terms and conditions, you must stop using our
              website.]
            </p>
            <p>
              <b>11.3.</b> If you have given your express agreement to these
              terms and conditions, we will ask for your express agreement to
              any revision of these terms and conditions; and if you do not give
              your express agreement to the revised terms and conditions within
              such period as we may specify, we will disable or delete your
              account on the website, and you must stop using the website.
            </p>
          </div>

          <div>
            <b>
              <b>12.</b>Assignment
            </b>
            <p>
              <b>12.1.</b>You hereby agree that we may assign, transfer,
              sub-contract or otherwise deal with our rights and/or obligations
              under these terms and conditions.
            </p>
            <p>
              <b>12.2.</b> You may not without our prior written consent assign,
              transfer, subcontract or otherwise deal with any of your rights
              and/or obligations under these terms and conditions.
            </p>
          </div>

          <div>
            <b>
              <b>13.</b>Severability
            </b>
            <p>
              <b>13.1.</b>If a provision of these terms and conditions is
              determined by any court or other competent authority to be
              unlawful and/or unenforceable, the other provisions will continue
              in effect.
            </p>
            <p>
              <b>13.2.</b>If any unlawful and/or unenforceable provision of
              these terms and conditions would be lawful or enforceable if part
              of it were deleted, that part will be deemed to be deleted, and
              the rest of the provision will continue in effect.
            </p>
          </div>

          <div>
            <b>
              <b>14.</b>Third party rights
            </b>
            <p>
              <b>14.1.</b>A contract under these terms and conditions is for our
              benefit and your benefit, and is not intended to benefit or be
              enforceable by any third party.
            </p>
            <p>
              <b>14.2.</b>The exercise of the parties' rights under a contract
              under these terms and conditions is not subject to the consent of
              any third party.
            </p>
          </div>

          <div>
            <b>
              <b>Entire agreement</b>
            </b>
            <p>
              <b>15.1.</b>The terms and conditions, together with [our privacy
              and cookies policy], shall constitute the entire agreement between
              you and us in relation to your use of our website and shall
              supersede all previous agreements between you and us in relation
              to your use of our website.
            </p>
          </div>

          <div>
            <b>
              <b>16.</b>Law and jurisdiction
            </b>
            <p>
              <b>16.1.</b>These terms and conditions shall be governed by and
              construed in accordance with Indian Law.
            </p>
            <p>
              <b>16.3.</b>Any disputes relating to these terms and conditions
              shall be subject to the [exclusive] OR [non-exclusive]
              jurisdiction of the courts of India.
            </p>
          </div>

          <div>
            <b>
              <b>17.</b>Our details.
            </b>
            <p>
              <b>17.1.</b>This website is owned and operated by Shalom Infotech.
            </p>
            <p>
              <b>17.2.</b>Our principal place of business is at Trichy, Tamil
              Nadu.
            </p>
            <p>
              <b>17.3.</b>You can contact us:
            </p>
          </div>

          <div className=" flex-col flex">
            <p>TVE Certification Services Pvt.Ltd.</p>
            <p>TVE International Academy Pvt.Ltd.,</p>
            <p>Plot No.5, Ganapathy Nagar,</p>
            <p>K.K. Nagar, Trichy - 620021,</p>
            <p>Tamil Nadu, India.</p>
            <b>+919361444418 </b>
            <b>0431-4051364</b>
          </div>
        </div>
  )
}

export default Policy3
